export const WALLET_BALANCE = "wallet.balance";
export const INVITATION_FROM = "invitation.from";
export const MESSAGE_TO_SIGN = "message.to.sign";
export const ME = "user.me";
export const REF_LINKS = "my.ref.inks";
export const CHECK_DOMAIN = "check.domain";
export const REGISTERED_USERS = "registered.users";
export const CALCULATE_CDO = "calculate.cdo";
export const BONUSES = "profile.bonuses";
export const REFERRAL_USERS = "referral.users";
export const REFERRAL_USERS_OTHER = "referral.users.other";
export const NFT_INFO = "nft.info";
export const MY_NFT = "my.nft";
export const MY_NFT_HEROES = "my.nft.heroes";
export const MY_HERO_PROGRESS = "my.nft.heroes.progress";
export const MY_DOMAINS = "my.domains";
export const ACTIVITIES = "activities";
export const DEPOSIT_ACTIVITIES = "deposit.activities";
export const PRIORITY_PASS_SUCCESS_INFO = "priority.pass.success.info";
export const GET_CONFIG_FROM_BACKEND = "get.config.from.backend";
export const PROMOTION_STATE = "get.promotion.state";
export const PROMOTION_TIME = "get.promotion.time";
export const PROMOTION_PASS = "get.promotion.priority.pass";
export const PREPARATION = "get.preparation.onchain.profile";
export const NFT_PORTFOLIO = "get.nft-portfolio";
