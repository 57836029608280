export const EARLY_SALE_DATE = Date.UTC(2023, 2, 13, 19, 0, 0, 0);
export const SALE_DATE =
  process.env.NEXT_PUBLIC_APP_CONFIG === "dev"
    ? Date.UTC(2023, 2, 20, 13, 30, 0, 0)
    : Date.UTC(2023, 2, 20, 19, 0, 0, 0);

export const EARLY_SALE_ENABLE = Date.now() > EARLY_SALE_DATE;
export const SALE_ENABLE = Date.now() > SALE_DATE;
export const DEPOSIT_MIN_VALIDATION =
  process.env.NEXT_PUBLIC_APP_CONFIG === "dev" ? 1.5 : 500;
