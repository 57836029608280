import Head from "next/head";

interface CustomHeadInterface {
  title?: string;
  subtitle?: string;
}

export const CustomHead = ({ title, subtitle }: CustomHeadInterface) => {
  const defaultTitle = "Candao";
  let customTitle;
  if (!title) {
    customTitle = defaultTitle;
  } else {
    customTitle = title;
  }

  if (!title && subtitle) {
    customTitle += " | " + subtitle;
  }

  return (
    <Head>
      <title>{customTitle}</title>
    </Head>
  );
};
