import axios from "axios";
import { apiUrl } from "../queries/config/api.config";
import {
  accessTokenKey,
  refreshTokenKey,
} from "../queries/wallet/loginWallet.query";
import { getCookie } from "react-use-cookie";
import { setToken } from "./setToken";
import Router from "next/router";

export async function authRequestOld(
  walletAddress: string,
  path: string,
  options?: any,
  body?: any,
  filters?: any,
) {
  let parsedBody;
  const url = new URL(`${apiUrl}${path}`);

  const BarerToken = getCookie(`${accessTokenKey}/${walletAddress}`);

  if (filters) {
    Object.keys(filters).forEach((i) => {
      url.searchParams?.append(i, filters[i]);
    });
  }

  if (body instanceof FormData) {
    parsedBody = body;
  } else {
    parsedBody = JSON.stringify(body);
  }

  const requestOptions = {
    method: "get",
    data: parsedBody || null,
    ...options,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${BarerToken}`,
      ...options?.headers,
    },
  };

  try {
    const response = await axios(url.href, requestOptions);
    return { data: response.data, status: response.status };
  } catch (error: any) {
    // * START -> TOKEN EXPIRED
    if (error.response.status === 401 || error.response.status === 403) {
      try {
        const url = new URL(`${apiUrl}auth/refresh-token`);
        const refreshToken = getCookie(`${refreshTokenKey}/${walletAddress}`);
        const refreshOptions = {
          method: "post",
          data: { refresh_token: refreshToken },
          headers: {
            "Content-Type": "application/json",
          },
        };
        // @ts-ignore
        try {
          const refreshResponse = await axios(url.href, refreshOptions);
          const {
            access_token,
            access_token_expiration_at,
            refresh_token,
            refresh_token_expiration_at,
          } = refreshResponse.data;

          // ? Access token
          setToken(
            `${accessTokenKey}/${walletAddress}`,
            access_token,
            access_token_expiration_at,
          );
          // ? Refresh token
          setToken(
            `${refreshTokenKey}/${walletAddress}`,
            refresh_token,
            refresh_token_expiration_at,
          );
        } catch (error: any) {
          if (
            error.response.request.responseURL.includes("auth/refresh-token")
          ) {
            Router.push("/login");
            throw {
              action: "LOGIN",
              data: error.response?.data,
              code: error.response?.status,
            };
          }
        }

        // * RETRY
        const BarerToken = getCookie(`${accessTokenKey}/${walletAddress}`);
        const requestOptions = {
          method: "get",
          data: parsedBody || null,
          ...options,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${BarerToken}`,
            ...options?.headers,
          },
        };

        const retriedResponse = await axios(url.href, requestOptions);
        return { data: retriedResponse.data, status: retriedResponse.status };
      } catch (error: any) {
        throw {
          action: error?.action,
          data: error?.response?.data,
          code: error?.response?.status,
        };
      }
    }
    // * END -> TOKEN EXPIRED
    const retriedResponse = await axios(url.href, requestOptions);
    return { data: retriedResponse.data, status: retriedResponse.status };
  }
}
