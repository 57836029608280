import { cva } from "class-variance-authority";

export const button = cva(
  "rounded-lg bg-[#111827] px-4 py-3 text-white flex gap-2 items-center",
  {
    variants: {
      variant: {
        google: "",
        apple: "",
      },
      state: {
        disabled: "cursor-not-allowed",
      },
    },
  },
);
