import { formatDistanceToNowStrict } from "date-fns";
import React from "react";
import Paragraph from "./typography/paragraph";
import { useTranslation } from "next-i18next";
import { useSelector } from "react-redux";
import { selectIsEarlyBirdsActive } from "../Redux/slices/config.slice";
import { EARLY_SALE_DATE, SALE_DATE } from "../constants/deployDates";

const Countdown = () => {
  const daysRef = React.useRef();
  const hourRef = React.useRef();
  const minutesRef = React.useRef();
  const secondsRef = React.useRef();
  const { t } = useTranslation("common");
  const isEarlyBirdsActive = useSelector(selectIsEarlyBirdsActive);

  React.useEffect(() => {
    function timer() {
      const seconds = parseFloat(
        formatDistanceToNowStrict(
          isEarlyBirdsActive ? SALE_DATE : EARLY_SALE_DATE,
          {
            unit: "second",
          },
        ).split(" ")[0],
      );
      const days = Math.floor(seconds / 24 / 60 / 60);
      const hoursLeft = Math.floor(seconds - days * 86400);
      const hours = Math.floor(hoursLeft / 3600);
      const minutesLeft = Math.floor(hoursLeft - hours * 3600);
      const minutes = Math.floor(minutesLeft / 60);
      const remainingSeconds = seconds % 60;

      function pad(n: any) {
        return n < 10 ? "0" + n : n;
      }

      if (
        daysRef.current &&
        hourRef.current &&
        minutesRef.current &&
        secondsRef.current
      ) {
        // @ts-ignore
        daysRef.current.innerHTML = pad(days);
        // @ts-ignore
        hourRef.current.innerHTML = pad(hours);
        // @ts-ignore
        minutesRef.current.innerHTML = pad(minutes);
        // @ts-ignore
        secondsRef.current.innerHTML = pad(remainingSeconds);
      }
    }
    setInterval(() => timer(), 1000);
  }, []);

  return (
    <>
      <div className="flex items-center justify-center">
        <Element rr={daysRef} name={t("days")} />
        <Element rr={hourRef} name={t("hours")} />
        <Element rr={minutesRef} name={t("minutes")} />
        <Element rr={secondsRef} name={t("seconds")} last />
      </div>
    </>
  );
};

export default Countdown;

const Element = ({
  name,
  last,
  rr,
}: {
  name: string;
  last?: boolean;
  rr: any;
}) => {
  return (
    <div
      className={`w-[88px] min-w-[88px] rounded-[16px] border bg-light2 px-3 py-3 shadow ${
        last ? "hidden md:block" : "mr-4"
      }`}
    >
      <p
        className="mb-1 text-center text-[24px] font-bold md:text-[36px]"
        ref={rr}
        id={name}
      ></p>
      <Paragraph uppercase caption center>
        {name}
      </Paragraph>
    </div>
  );
};
