import React from "react";
import { useQuery } from "react-query";
import { INVITATION_FROM } from "../../../../queries/config/keys";
import { getInvitingPerson } from "../../../../queries/boarding/invitingPerson.query";
import Image from "next/image";
import placeholderAvatar from "../../../../assets/images/placeholderAvatar.png";
import { useTranslation } from "next-i18next";

interface Props {
  accessCode: string | string[];
}

const InvitedByBadge = ({ accessCode }: Props) => {
  const { t } = useTranslation("common");
  const { data, isLoading, isError } = useQuery(
    [INVITATION_FROM, accessCode],
    getInvitingPerson,
  );

  if (isError) {
    return (
      <div data-aos="fade-up" data-aos-delay="1600">
        <div className="mt-8 flex items-center rounded-full border bg-white px-4 py-2 shadow md:mt-12">
          <p className="text-base text-gray3 text-red-400">
            {t("unfortunatelyYourAccessCodeInvalid")}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div data-aos="fade-up" data-aos-delay="1600">
      <div className="mt-12 flex items-center rounded-full border bg-white px-4 py-2 shadow">
        <p className="text-base text-gray3">{t("invitedBy")} </p>
        {isLoading ? (
          <div className="relative mx-2 h-8 w-8 animate-pulse overflow-hidden rounded-full bg-gray-200" />
        ) : (
          <div className="relative mx-2 h-8 w-8 overflow-hidden rounded-full">
            {data?.user.profile.avatar ? (
              <Image
                src={data?.user.profile.avatar}
                className="a w-full rounded-full object-cover"
                alt="user-avatar"
              />
            ) : (
              <Image
                src={placeholderAvatar}
                className="a w-full rounded-full object-cover"
                alt="user-avatar"
              />
            )}
          </div>
        )}
        {isLoading ? (
          <div className="h-5 w-24 animate-pulse rounded-lg bg-gray-200" />
        ) : (
          <p className="font-inter text-base font-medium text-pictionBlue">
            {data.user.name}
          </p>
        )}
      </div>
    </div>
  );
};

export default InvitedByBadge;
