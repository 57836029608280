import { request } from "../../utils/request/request";
import { QueriesOptions } from "react-query";

export const getInvitingPerson = async ({ queryKey }: QueriesOptions<any>) => {
  const [, accessCode] = queryKey;
  const response = await request(`access-code/${accessCode}/public-info`);
  return response.data;
};

export const getInvitingPersonMutation = async ({
  accessCode,
}: QueriesOptions<any>) => {
  const response = await request(`access-code/${accessCode}/public-info`);
  return response.data;
};
