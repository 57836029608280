import React from "react";
import logo1 from "../../../../assets/images/logo1.png";
import logo2 from "../../../../assets/images/logo2.png";
import logo3 from "../../../../assets/images/logo3.png";
import logo4 from "../../../../assets/images/logo4.png";
import logo5 from "../../../../assets/images/logo5.png";
import logo6 from "../../../../assets/images/logo6.webp";
import logo7 from "../../../../assets/images/logo7.webp";
import logo9 from "../../../../assets/images/logo9.webp";
import logo10 from "../../../../assets/images/logo10.webp";
import logo11 from "../../../../assets/images/logo11.webp";
import logo12 from "../../../../assets/images/logo12.webp";

import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useTranslation } from "next-i18next";

const FeaturedSection = () => {
  const { t } = useTranslation("common");
  return (
    <section
      className="featuredSection"
      data-aos="fade-up"
      data-aos-delay="300"
    >
      <div className="container">
        {/* <p className="name mb-7 text-center text-biggerBase text-[#696F8C]">
          {t('featuredIn')}
        </p> */}
        <p className="name mb-7 text-center text-biggerBase text-[#696F8C]">
          Featured in
        </p>
        <Swiper
          slidesPerView={"auto"}
          loopedSlides={10}
          speed={1200}
           modules={[Autoplay]}
          breakpoints={{
            0: {
              spaceBetween: 10,
            },
            768: {
              spaceBetween: 40,
            },
          }}
          autoplay={{
            delay: 3000,
          }}
          loop={true}
        >
          <SwiperSlide>
            <a
              href="https://bitcoinist.com/candao-is-launching-the-first-social-network-launchpad/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={logo1} alt="candao" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="https://www.marketwatch.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={logo2} alt="candao" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="https://hackernoon.com/ethereum-is-supposed-to-go-pos-proof-of-stake-in-2022-which-will-make-it-more-scalable"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={logo3} alt="candao" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <a
                href="https://www.investing.com/news/cryptocurrency-news/candao-is-launching-the-first-decentralized-social-media-dao-2690363"
                target="_blank"
                rel="noreferrer"
              >
                <Image src={logo4} alt="candao" />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="https://news.yahoo.com/candao-reinventing-nfts-being-used-103013326.html"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={logo5} alt="candao" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="https://coincodex.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={logo7} alt="candao" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="https://coingape.com/the-social-network-platform-candao-focuses-on-trust-while-utilizing-blockchain/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={logo9} alt="candao" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="https://zycrypto.com/connecting-with-talents-becomes-easier-with-candaos-decentralized-social-ecosystem/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={logo11} alt="candao" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="https://www.bitcoininsider.org/article/133463/candao-launch-first-decentralized-social-network-dao"
              target="_blank"
              rel="noreferrer"
              className="small"
            >
              <Image src={logo12} alt="candao" />
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default FeaturedSection;
