import { useTranslation } from "next-i18next";
import { useRouter } from "next/dist/client/router";
import dynamic from "next/dynamic";
import Image from "next/image";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import blurredBg from "../../../../assets/images/blureBg.png";
import lights from "../../../../assets/images/lights.png";
import { SALE_ENABLE } from "../../../../constants/deployDates";
import {
  selectEnableBlockchainLogic,
  selectIsEarlyBirdsActive,
} from "../../../../Redux/slices/config.slice";
import { setAccessCode } from "../../../../Redux/slices/onBoarding.slice";
import Countdown from "../../../countdown";
import { CountdownTimerSection } from "./countdownTimerSection";
import ElementsSection from "./elementsSection";
import InvitedByBadge from "./invitedByBadge";
import Bg from "../../../../public/assets/bg.svg";
import { AppsButton } from "@/v2/components/atoms/apps-button";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useSession } from "next-auth/react"

const PlayerSection = dynamic(() => import("../../../playerSection"), {
  ssr: false,
});
const WelcomeSection = () => {
  const router = useRouter();
  const inviteCode = router.query["code"];
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const isEarlyBirdsActive = useSelector(selectIsEarlyBirdsActive);
  const isEnabledBlockchainLogic = useSelector(selectEnableBlockchainLogic);
  const session = useSession();

  useEffect(() => {
    if(session.status === "authenticated"){
      router.push("/my-profile");
    }
  }, [session]);

  React.useEffect(() => {
    if (inviteCode) {
      dispatch(setAccessCode(inviteCode));
    }
  }, [dispatch, inviteCode]);

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  return (
    <section className="welcomeSection overflow-visible">
      {/* <Image src={blurredBg} alt="candao" className="bluredBg" />
      <Image src={lights} alt="candao" className="lights" /> */}
      <Bg className="absolute top-0 right-0 left-0 w-full"/>
      <div className="container">
        <div>
          {/* <p
            className="name text-center font-firs text-displayS md:text-displayM"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            {t('candaoProtocol')}
          </p> */}

          <CountdownTimerSection />

          <div data-aos="fade-up" data-aos-delay="800">
              <h1 className="type-wrap title mr-auto lg:px-10">
              {/* <span className="italic gradient block">{t('slogan')}</span> */}
              <span className="block text-[#101840]">
                Turn your <span className="gradient italic">Passions</span>
              </span>
              <span className="block text-[#101840]">
                Into Actions
              </span>
            </h1>
                      <p
            className="text-[20px] text-[#696F8C] text-center mb-5"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            Candao is a place that monetizes every second of your life
          </p>
          <p
            className="flex justify-center gap-2 text-center text-displayS md:text-displayM"
            // data-aos="fade-up"
            // data-aos-delay="400"
          >
          {/* <LightTooltip title="Waiting for Google review">
            <a
              // href="https://play.google.com/store/apps/details?id=com.candaowallet"
              // target="_blank"
            >
              <AppsButton />
            </a>
            </LightTooltip> */}
            <a
              href="https://apps.apple.com/pl/app/candao-wallet/id1662859456"
              target="_blank"
            >
              <AppsButton variant="apple" />
            </a>
          </p>
          </div>
          <div className="mb-6 pt-6">
            {/*  <Paragraph base gray1 center>*/}
            {/*    {SALE_ENABLE ? t('infoStart') : t('info')}*/}
            {/*  </Paragraph>*/}
            {/*  <div className="flex items-center justify-center mt-1">*/}
            {/*    <Paragraph base center>*/}
            {/*      <span className="gradientText">{t('info2')}</span>*/}
            {/*    </Paragraph>*/}
            {/*  </div>*/}
          </div>
          {(isEnabledBlockchainLogic && !isEarlyBirdsActive) || SALE_ENABLE ? (
            <></>
          ) : (
            <>
              <div data-aos="fade-up" data-aos-delay="1200">
                <Countdown />
              </div>
            </>
          )}

          <div className="flex flex-col items-center justify-center">
            {!!inviteCode && <InvitedByBadge accessCode={inviteCode} />}
          </div>

          <div className={"block"} data-aos="fade-up" data-aos-delay="1200">
            <PlayerSection />
          </div>
          <ElementsSection />
        </div>
      </div>
    </section>
  );
};

export default WelcomeSection;