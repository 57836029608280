import Head from "next/head";
import Footer from "../components/sections/pages/landing/footer";
import Navbar from "../components/sections/pages/landing/navbar";

import WelcomeSection from "../components/sections/pages/landing/welcomeSection";
import FeaturedSection from "../components/sections/pages/landing/featuredSection";
import JoinUsSection from "../components/sections/pages/landing/joinUsSection";
import Aos from "../components/sections/pages/landing/aos";
import React, { useEffect } from "react";
//import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { localesArray } from "../constants/localesArray";
import PromoInfoModal from "../components/modals/promoInfoModal";
import { useAccount, useConnect, useMutation } from "wagmi";
import { getCookie, setCookie } from "react-use-cookie";
import { useQuery } from "react-query";
import { ME } from "../queries/config/keys";
import { getLoggedUserData } from "../queries/user/me.query";
import { setLoggedUser } from "../Redux/slices/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import { accessTokenKey } from "../queries/wallet/loginWallet.query";
import { ErrorDto } from "../types/error.dto";
import { selectSelectedChainId } from "../Redux/slices/config.slice";
import { Flag } from "@mui/icons-material";
import { CustomHead } from "@/v2/components/molecules/head";
import { SessionProvider } from "next-auth/react"

export default function Home({ id, children }: { id?: any; children?: any }) {
  const [open, setOpen] = React.useState(false);
  const { isConnected, address, connector } = useAccount();
  const chainID = useSelector(selectSelectedChainId);
  const dispatch = useDispatch();
  React.useState(() => {
    if (
      !isConnected &&
      !getCookie("candao.promo.first.info") &&
      Date.now() < Date.UTC(2023, 4, 31, 23, 59, 59)
    ) {
      setOpen(true);
    }
    // @ts-ignore
  }, []);

  const handleCloseModal = () => {
    if (!getCookie("candao.promo.first.info")) {
      setCookie("candao.promo.first.info", "1", { days: 1 });
    }
    setOpen(false);
  };

  const BarerToken = getCookie(`${accessTokenKey}/${address}`);
  const { data: user, isLoading } = useQuery(
    [ME, BarerToken],
    getLoggedUserData,
    {
      enabled: !!BarerToken,
      retry: false,
      onSuccess: (data) => {
        dispatch(setLoggedUser(data));
      },
      onError: () => {},
    },
  );

  let flag: string | null;
  if (typeof window !== "undefined") {
    flag = localStorage.getItem("upadateFlag");
  }

  //console.log(useAccount())

  return (
    <>
      <CustomHead />
      <div className="bg-light3">
        <Aos />
        <Navbar />
        <main>
          {children}
          {/*<PromoInfoModal open={open} onClose={handleCloseModal} />*/}
          <SessionProvider>
            <WelcomeSection />
          </SessionProvider>
          <FeaturedSection />
          <JoinUsSection />
          {/*<ProjectsSection />*/}
        </main>
        <Footer />
      </div>
    </>
  );
}

// export async function getServerSideProps({ locale }: any) {
//   return {
//     props: {
//       ...(await serverSideTranslations(locale, localesArray)),
//     },
//   }
// }
