import React from "react";
import ChooseIcon from "../../../../assets/svgs/choose.svg";
import JoinIcon from "../../../../assets/svgs/join.svg";
import GetIcon from "../../../../assets/svgs/get.svg";
import { useTranslation } from "next-i18next";

const ElementsSection = () => {
  const { t } = useTranslation("common");

  return (
    <div data-aos="fade-up" data-aos-delay="800">
      <div className="row mb-2 mt-24">
        <div className="md:col-8 md:offset-2">
          <div className="row">
            <div className="text-left lg:col-4 lg:text-center">
              <div className="mb-6 flex items-center justify-center lg:mb-0 lg:flex-col">
                <ChooseIcon
                  className={"mr-4 h-[32px] w-auto lg:mb-6 lg:mr-0"}
                />
                {/* <p className="text-left lg:text-center text-base md:text-biggerBase text-gray1">
                  {t('chooseRoles')}
                </p> */}
                <p className="text-left text-base text-gray1 md:text-biggerBase lg:text-center">
                  Choose roles you can play
                </p>
              </div>
            </div>
            <div className="text-left lg:col-4 lg:text-center">
              <div className="mb-6 flex items-center justify-center lg:mb-0 lg:flex-col">
                <JoinIcon className={"mr-4 h-[32px] w-auto lg:mb-6 lg:mr-0"} />
                {/* <p className="text-left lg:text-center text-base md:text-biggerBase text-gray1">
                  {t('joinProjects')}
                </p> */}
                <p className="text-left text-base text-gray1 md:text-biggerBase lg:text-center">
                  Join projects & build together
                </p>
              </div>
            </div>
            <div className="text-left lg:col-4 lg:text-center">
              <div className="mb-6 flex items-center justify-center lg:mb-0 lg:flex-col">
                <GetIcon className={"mr-4 h-[32px] w-auto lg:mb-6 lg:mr-0"} />
                {/* <p className="text-left lg:text-center text-base md:text-biggerBase text-gray1">
                  {t('getRewards')}
                </p> */}
                <p className="text-left text-base text-gray1 md:text-biggerBase lg:text-center">
                  Get rewards distributed fairly to creators
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElementsSection;
