import React from "react";
import phone1 from "../../../../assets/images/phone1.png";
import phone2 from "../../../../assets/images/phone2.png";
import phone3 from "../../../../assets/images/phone3.png";
import phone4 from "../../../../assets/images/phone4.png";
import phone5 from "../../../../assets/images/phone5.png";
import phone6 from "../../../../assets/images/phone6.png";

import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "next-i18next";

const JoinUsSection = () => {
  const { t } = useTranslation("common");

  React.useEffect(() => {
    grabScroll();
  }, []);
  const grabScroll = () => {
    const sliders: any = document.querySelectorAll(".boxWrapper");
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    sliders.forEach((slider: any) => {
      if (window.innerWidth > 768) {
        const grabBox = document.getElementById("grabBox");
        if (grabBox) {
          slider.scroll({
            top: 0,
            left: grabBox.clientWidth / 2 - slider.clientWidth / 2,
          });
        }
      }

      const links = slider.querySelectorAll("a");
      let move = false;

      slider.addEventListener("mousedown", (e: any) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        setTimeout(() => {
          move = false;
        }, 200);
      });
      slider.addEventListener("mousemove", (e: any) => {
        if (!isDown) return;
        e.preventDefault();
        move = true;
        const x = e.pageX - slider.offsetLeft;
        const walk = x - startX; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });

      links.forEach((link: any) => {
        link.addEventListener("click", (e: any) => {
          move ? e.preventDefault() : false;
        });
      });
    });
  };

  return (
    <section id="join-as" className="joinAsSection">
      <div className="container">
        <div className="row">
          <div className="col-12 offset-0 lg:col-6 lg:offset-3">
            <div
              data-aos="fade-up"
              data-aos-delay="300"
              className="flex flex-col items-center justify-center"
            >
              {/* <h2 className="title mb-4 text-center text-[30px] font-semibold leading-[34px] text-[#101840] md:text-[40px] md:leading-[44px]">
                {t('avgCosts')}
              </h2> */}
              <h2 className="title mb-4 text-center text-[30px] font-semibold leading-[34px] text-[#101840] md:text-[40px] md:leading-[44px]">
                80% of average startup costs are people.
              </h2>
              {/* <p className="desc text-center text-heading font-normal text-[#696F8C]">
                {t('avgText')}
              </p> */}
              <p className="desc text-center text-heading font-normal text-[#696F8C]">
                Everyone usually seeks money in order to pay the team. Let&apos;s
                try a different approach here. Collect intellect instead of
                money.
              </p>
              {/* <p className="comingSoonText">
                {t('comingSoon')}
              </p> */}
              <p className="comingSoonText">Coming soon!</p>
            </div>
          </div>
          <div className="col-12">
            <div
              data-aos="fade-up"
              data-aos-delay="600"
              className="swiperScreensBox"
            >
              <Swiper
                initialSlide={1}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="phoneBox">
                    {/* <p className="comingSoonText">{t('comingSoon')}</p> */}
                    <p className="comingSoonText">Coming soon!</p>
                    <Image src={phone1} alt="candao" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="phoneBox">
                    {/* <p className="comingSoonText">{t('comingSoon')}</p> */}
                    <p className="comingSoonText">Coming soon!</p>
                    <Image src={phone2} alt="candao" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="phoneBox">
                    {/* <p className="comingSoonText">{t('comingSoon')}</p> */}
                    <p className="comingSoonText">Coming soon!</p>
                    <Image src={phone3} alt="candao" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="phoneBox">
                    {/* <p className="comingSoonText">{t('comingSoon')}</p> */}
                    <p className="comingSoonText">Coming soon!</p>
                    <Image src={phone4} alt="candao" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="phoneBox">
                    {/* <p className="comingSoonText">{t('comingSoon')}</p> */}
                    <p className="comingSoonText">Coming soon!</p>
                    <Image src={phone5} alt="candao" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="phoneBox">
                    {/* <p className="comingSoonText">{t('comingSoon')}</p> */}
                    <p className="comingSoonText">Coming soon!</p>
                    <Image src={phone6} alt="candao" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="col-12">
            <div
              data-aos="zoom-in"
              data-aos-delay="600"
              className="positionsBox mt-6 md:mt-12"
            >
              <div className="positionsWrapper">
                <div
                  className="boxWrapper"
                  style={{ maxHeight: "292px", maxWidth: "100%" }}
                >
                  <div
                    id="grabBox"
                    className="box flex flex-wrap justify-start md:justify-center"
                    style={{ width: "221vw" }}
                  >
                    {Array.from({ length: 5 }).map((_, index) => (
                      <Positions key={index} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinUsSection;

// const Positions = () => {
//   const { t: t_positions } = useTranslation('common')

//   return (
//     <>
//       {[
//         0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
//         20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
//       ]
//         .sort((a, b) => 0.5 - Math.random())
//         .map((number, index) => (
//           <span key={index} className="positionBadge">
//             {t_positions(`pos${number + 1}`)}
//           </span>
//         ))}
//     </>
//   )
// }

const Positions = () => {
  const keywords = [
    "Computer Systems Analyst",
    "Web Developer",
    "Database Administrator",
    "Accountant",
    "Computer Network Architect",
    "Lawyer",
    "IT Manager",
    "Market Research Analyst",
    "Management Analyst",
    "Marketing Manager",
    "Business Operations Manager",
    "Information Security Analyst",
    "Software Developer",
    "Data Scientist",
    "Financial Manager",
    "Mobile Application Developer",
    "Big Data Engineer",
    "Product Designer",
    "Web Designer",
    "Artificial Intelligence Engineer",
    "DevOps Engineer",
    "Computer Systems Administrator",
    "Sales Manager",
    "Cloud Architect",
    "Blockchain Engineer",
    "Digital Marketing Manager",
    "Python Developer",
    "Product Manager",
    "Machine Learning Engineer",
    "Tech Sales Engineer",
  ];

  const shuffledWords = [...keywords].sort(() => 0.5 - Math.random());

  return (
    <>
      {keywords.map((word, index) => (
        <span key={index} className="positionBadge">
          {word}
        </span>
      ))}
    </>
  );
};
