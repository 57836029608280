import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

import { button } from "./styles";
import { ButtonProps } from "./types";
import { Icon } from "../icon";

export const AppsButton = ({
  variant = "google",
  className,
  children,
  ...props
}: PropsWithChildren<ButtonProps>) => {

  return (
    <button
      className={cn(
        button({
          variant,
          className,
        }),
      )}
      {...props}
    >
      <Icon name={variant == "google" ? "googleplay" : "apple"} color="red" />
      <div className="flex flex-col text-left gap-.5">
      <span className="uppercase text-[11px] leading-none text-[#D1D5DB]">{variant == "google" ? "Get it On" : "Download On"} </span>
      <span className="text-[14px] font-medium leading-none	">{variant == "google" ? "Google Play" : "App Store"}</span>
      </div>
      {children}
    </button>
  );
};
