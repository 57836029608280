import { useTimer } from "react-timer-hook";

import { CountdownTimer } from "@/v2/components/molecules/countdown-timer/countdown-timer";

export const CountdownTimerSection = () => {
  const expiryTimestamp = new Date(Date.UTC(2024, 5, 18, 14, 0, 0));

  const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp });

  const getCurrentTime = Math.floor(Date.now() / 1000);

  return Math.floor((expiryTimestamp as any) / 1000) < getCurrentTime ? (
    <div className="pb-2 text-center text-[24px] font-medium text-[#5850EC]">
      Candao is LIVE 🎉
    </div>
  ) : (
    <div className="mb-5 flex flex-col gap-5 text-center md:mb-10">
      <p className="text-sm font-medium text-blueText md:text-[16px]">
        Stay tuned, We will launch soon!
      </p>
      <CountdownTimer
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    </div>
  );
};
