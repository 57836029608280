import { request } from "../../utils/request/request";
import { LoginWalletDto } from "../../types/auth/loginWallet.dto";
import { setToken } from "../../utils/setToken";

export const accessTokenKey =
  process.env.NEXT_PUBLIC_ACCESS_TOKEN_COOKIE ?? "candao.io.accesstoken";
export const refreshTokenKey =
  process.env.NEXT_PUBLIC_REFRESH_TOKEN_COOKIE ?? "candao.io.refreshtoken";

export const loginWalletQuery = async (values: {
  walletAddress: string;
  dto: LoginWalletDto;
}) => {
  const response = await request(`auth/login`, { method: "POST" }, values.dto);
  const {
    access_token,
    access_token_expiration_at,
    refresh_token,
    refresh_token_expiration_at,
  } = response.data;
  // ? Access token
  setToken(
    `${accessTokenKey}/${values.walletAddress}`,
    access_token,
    access_token_expiration_at,
  );
  // ? RefreshToken token
  setToken(
    `${refreshTokenKey}/${values.walletAddress}`,
    refresh_token,
    refresh_token_expiration_at,
  );
  return response.data;
};
