import { authRequestOld } from "../../utils/authRequestOld";
import { getAccount } from "@wagmi/core";

export const getLoggedUserData = async () => {
  const account = getAccount();
  const response: any = await authRequestOld(
    account.address ?? "0x0",
    `user/me`,
  );
  return { ...response.data, action: response.action };
};
